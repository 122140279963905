<template>
  <div>
    <div class="admin">
      <h3>
        <img
          class="logo"
          src="@/img/logo.png"
          alt="インテリジェントヘルスケア ストレスチェックApp"
        />
      </h3>
      <h3>案件一覧</h3>
      <div
        v-show="
          !$store.state.common.sIsLoading && !$store.state.common.sDataLoading
        "
      >
        <div>
          <div class="ak01">
            <table>
              <thead>
                <tr>
                  <th colspan="2">
                    <a
                      href="javascript:window.location.replace('#modal1')"
                      class=""
                      >アンケート用紙</a
                    >
                  </th>
                </tr>
                <tr>
                  <th>企業名</th>
                  <th>ストレスチェック開始日</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="implementation_datas['questionnaire']">
                  <template
                    v-for="li in implementation_datas['questionnaire']"
                    :key="li.com_id"
                  >
                    <tr>
                      <td>
                        <router-link :to="'/intelligent-detail/' + li.sc_id">{{
                          li.com_name
                        }}</router-link>
                      </td>
                      <td>{{ li.sc_answer_start_date }}</td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
          <div class="ak01">
            <table>
              <thead>
                <tr>
                  <th colspan="2">
                    <a
                      href="javascript:window.location.replace('#modal2')"
                      class=""
                      >実施期間待ち</a
                    >
                  </th>
                </tr>
                <tr>
                  <th>企業名</th>
                  <th>ストレスチェック開始日</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="implementation_datas['zissi_waiting']">
                  <template
                    v-for="li in implementation_datas['zissi_waiting']"
                    :key="li.com_id"
                  >
                    <tr>
                      <td>
                        <router-link :to="'/intelligent-detail/' + li.sc_id">{{
                          li.com_name
                        }}</router-link>
                      </td>
                      <td>{{ li.sc_answer_start_date }}</td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
          <div class="ak01">
            <table>
              <thead>
                <tr>
                  <th colspan="2">
                    <a
                      href="javascript:window.location.replace('#modal3')"
                      class=""
                      >実施期間中</a
                    >
                  </th>
                </tr>
                <tr>
                  <th>企業名</th>
                  <th>ストレスチェック終了日</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="implementation_datas.now">
                  <template
                    v-for="li in implementation_datas.now"
                    :key="li.com_id"
                  >
                    <tr>
                      <td>
                        <router-link :to="'/intelligent-detail/' + li.sc_id">{{
                          li.com_name
                        }}</router-link>
                      </td>
                      <td>{{ li.sc_answer_end_date }}</td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
          <div class="ak01">
            <table>
              <thead>
                <tr>
                  <th colspan="2">
                    <a
                      href="javascript:window.location.replace('#modal4')"
                      class=""
                      >対応待ち企業一覧</a
                    >
                  </th>
                </tr>
                <tr>
                  <th>企業名</th>
                  <th>ストレスチェック終了日</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="implementation_datas['waiting']">
                  <template
                    v-for="li in implementation_datas['waiting']"
                    :key="li.com_id"
                  >
                    <tr>
                      <td>
                        <router-link :to="'/intelligent-detail/' + li.sc_id">{{
                          li.com_name
                        }}</router-link>
                      </td>
                      <td>{{ li.sc_answer_end_date }}</td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
          <div class="ak01">
            <table>
              <thead>
                <tr>
                  <th colspan="2">
                    <a
                      href="javascript:window.location.replace('#modal4')"
                      class=""
                      >分析中</a
                    >
                  </th>
                </tr>
                <tr>
                  <th>企業名</th>
                  <th>ストレスチェック終了日</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="implementation_datas['aws_process']">
                  <template
                    v-for="li in implementation_datas['aws_process']"
                    :key="li.com_id"
                  >
                    <tr>
                      <td>
                        <router-link :to="'/intelligent-detail/' + li.sc_id">{{
                          li.com_name
                        }}</router-link>
                      </td>
                      <td>{{ li.sc_answer_end_date }}</td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
          <div class="ak01">
            <table>
              <thead>
                <tr>
                  <th colspan="2">
                    <a
                      href="javascript:window.location.replace('#modal5')"
                      class=""
                      >実施済み企業一覧</a
                    >
                  </th>
                </tr>
                <tr>
                  <th>企業名</th>
                  <th>ストレスチェック終了日</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="implementation_datas['done']">
                  <template
                    v-for="li in implementation_datas['done']"
                    :key="li.com_id"
                  >
                    <tr>
                      <td>
                        <router-link :to="'/intelligent-detail/' + li.sc_id">{{
                          li.com_name
                        }}</router-link>
                      </td>
                      <td>{{ li.sc_answer_end_date }}</td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
        </div>

        <div id="modal1" class="overlays">
          <a class="cancel" href="javascript:window.location.replace('#')"></a>
          <div class="modals">
            <h2>アンケート用紙</h2>
            <div class="cont">
              <table>
                <thead>
                  <tr class="fixed01">
                    <th>企業名</th>
                    <th>ストレスチェック開始日</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="implementation_datas['questionnaire']">
                    <template
                      v-for="li in implementation_datas['questionnaire']"
                      :key="li.com_id"
                    >
                      <tr
                        @click="$router.push('/intelligent-detail/' + li.sc_id)"
                      >
                        <td>
                          {{ li.com_name }}
                        </td>
                        <td>{{ li.sc_answer_start_date }}</td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </table>
              <p class="close">
                <a href="javascript:window.location.replace('#')">×</a>
              </p>
            </div>
          </div>
        </div>
        <div id="modal2" class="overlays">
          <a class="cancel" href="javascript:window.location.replace('#')"></a>
          <div class="modals">
            <h2>実施期間待ち</h2>
            <div class="cont">
              <table>
                <thead>
                  <tr class="fixed01">
                    <th>企業名</th>
                    <th>ストレスチェック開始日</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="implementation_datas['zissi_waiting']">
                    <template
                      v-for="li in implementation_datas['zissi_waiting']"
                      :key="li.com_id"
                    >
                      <tr
                        @click="$router.push('/intelligent-detail/' + li.sc_id)"
                      >
                        <td>
                          {{ li.com_name }}
                        </td>
                        <td>{{ li.sc_answer_start_date }}</td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </table>
              <p class="close">
                <a href="javascript:window.location.replace('#')">×</a>
              </p>
            </div>
          </div>
        </div>
        <div id="modal3" class="overlays">
          <a class="cancel" href="javascript:window.location.replace('#')"></a>
          <div class="modals">
            <h2>実施期間中</h2>
            <div class="cont">
              <table>
                <thead>
                  <tr class="fixed01">
                    <th>企業名</th>
                    <th>ストレスチェック終了日</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="implementation_datas.now">
                    <template
                      v-for="li in implementation_datas.now"
                      :key="li.com_id"
                    >
                      <tr
                        @click="$router.push('/intelligent-detail/' + li.sc_id)"
                      >
                        <td>
                          {{ li.com_name }}
                        </td>
                        <td>{{ li.sc_answer_end_date }}</td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </table>
              <p class="close">
                <a href="javascript:window.location.replace('#')">×</a>
              </p>
            </div>
          </div>
        </div>

        <div id="modal4" class="overlays">
          <a class="cancel" href="javascript:window.location.replace('#')"></a>
          <div class="modals">
            <h2>対応待ち企業一覧</h2>
            <div class="cont">
              <table>
                <thead>
                  <tr class="fixed01">
                    <th>企業名</th>
                    <th>ストレスチェック終了日</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="implementation_datas['waiting']">
                    <template
                      v-for="li in implementation_datas['waiting']"
                      :key="li.com_id"
                    >
                      <tr
                        @click="$router.push('/intelligent-detail/' + li.sc_id)"
                      >
                        <td>
                          {{ li.com_name }}
                        </td>
                        <td>{{ li.sc_answer_sc_answer_end_date }}</td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </table>
              <p class="close">
                <a href="javascript:window.location.replace('#')">×</a>
              </p>
            </div>
          </div>
        </div>

        <div id="modal5" class="overlays">
          <a class="cancel" href="javascript:window.location.replace('#')"></a>
          <div class="modals">
            <h2>実施済み企業一覧</h2>
            <div class="cont">
              <table>
                <thead>
                  <tr class="fixed01">
                    <th>企業名</th>
                    <th>ストレスチェック終了日</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="implementation_datas['done']">
                    <template
                      v-for="li in implementation_datas['done']"
                      :key="li.com_id"
                    >
                      <tr
                        @click="$router.push('/intelligent-detail/' + li.sc_id)"
                      >
                        <td>
                          {{ li.com_name }}
                        </td>
                        <td>{{ li.sc_answer_end_date }}</td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </table>
              <p class="close">
                <a href="javascript:window.location.replace('#')">×</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      status: false,
      implementation_datas: {
        questionnaire: null,
        zissi_waiting: null,
        now: null,
        waiting: null,
        done: null,
      },
    };
  },
  mounted() {
    this.$store.state.common.sIsLoading = false;
    document.title = "インテリジェントヘルスケア";
  },
  created() {
    this.$store.state.common.sIsLoading = true;
    this.$store.state.common.sDataLoading = true;
    this.Loading = false;
    this.status = this.$store.state.login_status_i;
    this.checkLogined();
    if (this.status) {
      this.get_implementation_datas();
    }
  },
  methods: {
    get_implementation_datas() {
      this.$axios
        .get("/intelligent/get_implementation")
        .then(
          function (response) {
            if (response.data.flg) {
              console.log(response);
              this.implementation_datas["questionnaire"] =
                response.data.implementation_datas["questionnaire"];
              this.implementation_datas["zissi_waiting"] =
                response.data.implementation_datas["zissi_waiting"];
              this.implementation_datas["now"] =
                response.data.implementation_datas["now"];
              this.implementation_datas["waiting"] =
                response.data.implementation_datas["waiting"];
              this.implementation_datas["done"] =
                response.data.implementation_datas["done"];
              this.implementation_datas["aws_process"] =
                response.data.implementation_datas["aws_process"];
              console.log(this.implementation_datas);
            }
            this.$store.state.common.sDataLoading = false;
          }.bind(this)
        )
        .catch(
          function (error) {
            console.log(error);
            this.$store.state.common.sDataLoading = false;
          }.bind(this)
        );
    },
    checkLogined() {
      if (!this.status) {
        this.$router.push("/intelligent-login");
      }
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border: 2px solid black;
  border-radius: 6px;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

table tbody tr {
  border-width: 1;
  border-style: solid;
}
table tbody tr td {
  text-align: center;
}

.border-red {
  border-color: red;
}

.border-aqua {
  border-color: aqua;
}

.border-lime {
  border-color: lime;
}
.border-skyblue {
  border-color: skyblue;
}

.overlays {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 200ms;
  visibility: hidden;
  opacity: 0;
}

.overlays .cancel {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  cursor: default;
}

.overlays:target {
  visibility: visible;
  opacity: 1;
}

.modals {
  margin: 0px auto;
  padding: 20px;
  background: #fff;
  border: 1px solid #666;
  width: 80%;
  height: 90%;
  border-radius: 6px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
  position: relative;
}

.modals h2 {
  margin-top: 0;
}

.modals .close {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 20px;
  right: 20px;
  opacity: 0.8;
  transition: all 200ms;
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
  color: #777;
}

.modals .close:hover {
  opacity: 1;
}

.modals .cont {
  overflow-y: auto;
  max-height: 80vh;
}

.modals p {
  margin: 0 0 1em;
  text-align: center;
}

.modals p:last-child {
  margin: 0;
}

a.button:hover {
  box-shadow: 0 12px 23px rgba(0, 0, 0, 0.23), 0 10px 10px rgba(0, 0, 0, 0.19);
}

.overlays table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.overlays table tr {
  border-bottom: solid 1px #eee;
  cursor: pointer;
}

.overlays table tbody tr:hover {
  background-color: #d4f0fd;
}

.overlays table th,
.overlays table td {
  text-align: center;
  width: 10%;
  padding: 15px 0;
}

.fixed01 {
  position: sticky;
  top: 0;
  color: #fff;
  background: #333;

  &:before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
    border: 1px solid #eee;
  }
}
[v-cloak] {
  display: none;
  cursor: wait;
}
</style>
